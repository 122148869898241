import React from 'react'
import { navigate } from 'gatsby'

import Logo from '../../../images/logo.png'

import Firebase from '../../../service/Firebase'

import * as S from './styles'

function Header() {
  const handleLogout = () => {
    const firebase = new Firebase()
    firebase.logout()
    navigate('/signin')
  }

  return (
    <S.HeaderWrapper>
      <S.HeaderContainer>
        <S.HeaderLogoLoged onClick={() => navigate('/')}>
          <S.ImageLogo src={Logo} alt="Logo coruja" />
        </S.HeaderLogoLoged>
        <S.HeaderLogoutButton onClick={handleLogout}>SAIR</S.HeaderLogoutButton>
      </S.HeaderContainer>
    </S.HeaderWrapper>
  )
}

export default Header
