import React, { useEffect, useState } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby'

import { FirebaseInstance } from '../service/Firebase'
import Layout from '../components/Layout/admin'
import Box from '@material-ui/core/Box'

import * as S from '../styles/styleContactList'

import SEO from '../components/seo'

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  }
}))

const Contact = () => {
  const firebase = FirebaseInstance
  const [contactList, setContactList] = useState([])
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    const loadData = async () => {
      try {
        setOpen(true)
        const list = await firebase.getAll()
        setContactList(list)

        setOpen(false)
      } catch (error) {
        toast.error(error.message)
        navigate('/signin')
      } finally {
        setOpen(false)
      }
    }

    loadData()
  }, [])

  const handleDelete = async (contact) => {
    const deleted = await firebase.delete(contact)
    if (deleted) {
      const newList = contactList.filter(
        (register) => register.id !== contact.id
      )

      setContactList(newList)
      toast.success('Registro deletado com sucesso!')
    } else {
      toast.error('Ops... erro ao deletar o registro, contate o suporte!')
    }
  }

  return (
    <Layout>
      <SEO title="Contatos" />
      <Backdrop className={classes.backdrop} open={open}>
        <span style={{ marginBottom: '10px', fontSize: '16px' }}>
          Carregando lista de contatos
        </span>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box>
        {contactList.map((item, idx) => (
          <S.InformationsWrapper key={idx}>
            <S.Informations>
              {Object.entries(item).map((it, idxp) => {
                if (it[0] !== 'id')
                  return (
                    <h2 key={idxp}>
                      {it[0].capitalize()}: {it[1]}
                    </h2>
                  )
              })}
            </S.Informations>
            <S.ActionContainer>
              <S.DeleteContactButton
                type="button"
                onClick={() => handleDelete(item)}
              >
                Apagar
              </S.DeleteContactButton>
            </S.ActionContainer>
          </S.InformationsWrapper>
        ))}
      </Box>
    </Layout>
  )
}

export default Contact
