import styled from 'styled-components'
import media from 'styled-media-query'
import { darken } from 'polished'

export const InformationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  padding: 10px;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const ActionContainer = styled.div``

export const Informations = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h2 {
    word-wrap: break-word;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`

export const DeleteContactButton = styled.button`
  color: #fff;
  padding: 1rem 2rem;
  background: red;
  border-radius: 5px;
  font-weight: bold;
  border: 0;
  cursor: pointer;

  &:hover {
    background: ${darken(0.1, 'red')};
  }

  ${media.lessThan('medium')`
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
  `}
`
