import React from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'

import { ToastContainer } from 'react-toastify'
import Header from '../Contact/Header'

import GlobalStyles from '../../styles/global'

export default function LayoutAdmin({ children }) {
  return (
    <React.Fragment>
      <CssBaseline />
      <ToastContainer autoClose={3000} />
      <GlobalStyles />
      <Header />
      <Container maxWidth="lg">{children}</Container>
    </React.Fragment>
  )
}

LayoutAdmin.propTypes = {
  children: PropTypes.node.isRequired
}
