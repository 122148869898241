import styled from 'styled-components'
import media from 'styled-media-query'
import { darken } from 'polished'

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100px;
  background: #303049;
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    height: 80px;
  `}
`

export const HeaderLogoLoged = styled.div`
  display: flex;
  align-items: center;
`

export const ImageLogo = styled.img`
  height: 90px;
  width: 170px;
  cursor: pointer;

  ${media.lessThan('medium')`
    height: 70px;
    width: 140px;
  `}
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
`

export const HeaderLogoutButton = styled.div`
  color: #fff;
  padding: 1rem 2rem;
  background: red;
  border-radius: 5px;
  font-weight: bold;
  border: 0;
  cursor: pointer;

  &:hover {
    background: ${darken(0.1, 'red')};
  }

  ${media.lessThan('medium')`
    padding: 0.5rem 1.5rem;
  `}
`
